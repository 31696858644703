.row_card_section {
  background-color: #fff;
}
.row_card_section .btn {
  width: 200px;
}

#about {
  padding: 20px 40px;
}

.about_section {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 20px;
}

.user__image img {
  width: 100%;
  border-radius: 20px;
}

.user__content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: justify;
  justify-content: center;
}

@media (max-width: 992px) {
  .about_section {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }

  .user__image {
    width: 60%;
    margin: auto;
    grid-row-start: 1;
    grid-row-end: 2;
  }
}

@media (max-width: 768px) {
  .user__image {
    width: 75%;
  }
}

@media (max-width: 576px) {
  .user__image {
    width: 100%;
  }

  #about {
    padding: 20px;
  }
}
