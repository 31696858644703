@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Italianno&family=Poppins:wght@300;400;600&family=Roboto:ital,wght@0,100;0,400;0,700;1,400;1,700&family=Tangerine&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth !important;

  /*
  font-family: 'Great Vibes', cursive;
  font-family: 'Italianno', cursive;
  font-family: 'Poppins', sans-serif;
  font-family: 'Roboto', sans-serif;
  font-family: 'Tangerine', cursive;
 */
  font-family: "Poppins", sans-serif;
  background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*************************************/
/* COMMON CODE FOR MANY COMPONENTS */
/*************************************/
p,
span {
  line-height: 1.5;
}

a {
  text-decoration: none;
}

.btn {
  background-color: #fa5252;
  border: none;
  padding: 12px 20px;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
}

.btn:hover {
  background-color: #fa3a3a;
  box-shadow: 0 1.2rem 3.2rem rgba(44, 111, 207, 0.2);
}

.caps__title {
  color: #000;
}

.highlight__title {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 48px;
}

.sub__title {
  color: #777;
  margin-bottom: 10px;
}

#about .highlight__title,
#products .highlight__title,
#testimonial .highlight__title,
#contact .highlight__title {
  font-family: "Great Vibes", cursive;
}

.links__btn {
  background-color: #fd5757;
  color: #fff;
  padding: 10px;
  border-radius: 20px;
}

.links__btn:hover {
  background-color: #fb2e2e;
}

/******************************/
/***** COMMON MEDIA QUERIES */
/*****************************/
@media (max-width: 1050px) {
  .highlight__title {
    font-size: 40px;
  }
  .sub__title {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .highlight__title {
    font-size: 32px;
  }
}

@media (max-width: 400px) {
  .highlight__title {
    font-size: 28px;
  }
}
