.testimonial_card {
  padding: 20px;
}

.testimonial__message {
  text-align: center;
}

.testimonial_card_innerBox {
  box-shadow: inset 0 1.2rem 3.2rem rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 20px;
  min-height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  transition: all 0.3s;
}

.testimonial__name {
  font-weight: 800;
}

.testimonial_card_innerBox:hover {
  background-color: rgb(253, 105, 105);
  color: #fff;
}
