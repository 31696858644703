.heading__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}

.heading__container .sub__title {
  text-align: center;
}
