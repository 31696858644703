.footer {
  background-color: rgb(4, 9, 30);
  color: #fff;
}
.footer__links__container {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.footer__copyright {
  text-align: center;
  padding: 10px 0;
  font-family: "Great Vibes", cursive;
}

.footer__logo_container {
  overflow: hidden;
}

.footer__logo_container img {
  height: 120px;
}

.footer__details {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
  max-width: 80%;
}

.footer__details a {
  text-decoration: none;
  color: #fff;
}

.footer__details a:hover {
  text-decoration: underline;
}

.footer__cols h4 {
  margin-bottom: 10px;
  position: relative;
  left: 5px;
  font-size: 18px;
  font-family: "Great Vibes", cursive;
}

.footer__logo_container h2 {
  font-family: "Great Vibes", cursive;
  margin-bottom: 12px;
}

.quick__links__col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 140px;
}

.social_media_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.social_media_container a {
  color: #fff;
  transition: all 0.3s;
  height: 36px;
}

.social_media_container a:nth-child(1):hover {
  color: #1da1f2;
}

.social_media_container a:nth-child(2):hover {
  color: #32d953;
}

.social_media_container a:nth-child(3):hover {
  color: #ac29aa;
}

.social_media_container a:nth-child(4):hover {
  color: #4267b2;
}

.social_media_container a svg {
  font-size: 40px;
}

.social_media_heading {
  text-align: center;
  margin-bottom: 10px;
  font-family: "Great Vibes", cursive;
}

@media (max-width: 1280px) {
  .quick__links__col {
    padding-left: 100px;
  }
}

@media (max-width: 992px) {
  .social_media_heading {
    text-align: left;
  }

  .footer__links__container {
    grid-template-columns: 1fr;
    row-gap: 20px;
    padding-left: 50px;
  }
  .quick__links__col {
    padding-left: 0;
  }
  .social_media_container {
    justify-content: flex-start;
  }
}

@media (max-width: 576px) {
  .footer__details {
    max-width: 100%;
  }

  .footer__links__container {
    padding-left: 25px;
  }
}
