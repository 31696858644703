.hero_section {
  min-height: 90vh;
  display: grid;
  place-content: center;
  text-align: center;
  row-gap: 10px;
}

.hero_section_btn {
  border-radius: 20px;
}

.welcome_text {
  color: #fff;
  font-size: 42px;
  font-weight: 800;
  font-family: "Great Vibes", cursive;
}

.highlight_name_text {
  color: #fff;
  font-size: 64px;
  font-weight: 800;
  font-family: "Great Vibes", cursive;
}

.desc__text {
  color: #fff;
  width: 70%;
  margin: auto;
  text-align: justify;
}

@media (max-width: 992px) {
  .desc__text {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .desc__text {
    width: 90%;
  }
}
@media (max-width: 576px) {
  .welcome_text {
    font-size: 32px;
  }

  .highlight_name_text {
    font-size: 48px;
  }
}
@media (max-width: 450px) {
  .welcome_text {
    font-size: 24px;
  }

  .highlight_name_text {
    font-size: 42px;
  }
  .desc__text {
    font-size: 14px;
  }
}
