#products {
  padding: 20px 40px;
}

.product__card {
  display: flex;
  justify-content: center;
}

.product__card img {
  width: 300px;
  height: 300px;
}

@media (max-width: 400px) {
  .product__card img {
    width: 250px;
    height: 250px;
  }
  #products {
    padding: 20px;
  }
}
