.num_counter_section {
  padding: 20px 40px;
  display: grid;
  grid-template-columns: 250px 250px 250px 250px;
  column-gap: 40px;
  place-content: center;
}

.num_counter_card {
  background-color: rgba(249, 191, 191, 0.5);
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: all 0.3s;
}

.num_counter_card:hover {
  background-color: rgb(253, 105, 105);
  color: #fff;
  transform: scale(1.05);
}

.num_counter_card img {
  width: 68px;
  height: 68px;
}

.counter__text {
  font-size: 42px;
  font-weight: 800;
  font-family: "Roboto", sans-serif;
}

.counter__name {
  font-weight: 500;
}

@media (max-width: 1200px) {
  .num_counter_section {
    grid-template-columns: 250px 250px;
    row-gap: 30px;
    column-gap: 30px;
  }
}

@media (max-width: 600px) {
  .num_counter_section {
    grid-template-columns: 200px 200px;
  }
}

@media (max-width: 500px) {
  .num_counter_section {
    grid-template-columns: 150px 150px;
    row-gap: 10px;
    column-gap: 10px;
  }
  .num_counter_card img {
    width: 42px;
    height: 42px;
  }

  .counter__text {
    font-size: 24px;
  }

  .counter__name {
    font-weight: 500;
    font-size: 12px;
  }
}
