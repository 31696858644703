.loader_parent {
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
  display: grid;
  place-content: center;
}

.loader_parent svg path,
.loader_parent svg rect {
  fill: #9d444a;
}
